<template>
<div class="main">
  <div id="app">
    <Navbar color_rgb="#000000" :trasparente="grg_var_transparencia"></Navbar>
    <router-view/>
    <Footer></Footer>
  </div>
</div>
  
</template>

<script>
import {mapState,mapMutations} from 'vuex';
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: 'App',
  components:{
    Navbar,
    Footer
  },
  created(){
    window.addEventListener('scroll', this.detectarScroll);
    window.addEventListener("resize", this.detectarViewport);
    this.detectarViewport();
    localStorage.setItem("token", '1773215a188eb0ce1aef7214e29f1999e3954721f7838a8cb121f7bb8c5353b8e5b165bdc56436f153bab32ff692ee74e2c87f856b2874db74c5302458d1aef1');
  },
  computed:{
    ...mapState(['grg_var_scroll','grg_var_view_port_md','grg_var_transparencia']),
  },
  methods:{
    ...mapMutations(['grgFuncionChangeScroll','grgFuncionChangeViewPort','grgFuncionChangeTransparencia']),
    detectarScroll(evt, el){
      // console.log(window.scrollY)
      AOS.init({
        offset: 50, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
      });
      if(window.scrollY > 370){
        this.grgFuncionChangeScroll(true);
      }
      if(window.scrollY < 345){
        this.grgFuncionChangeScroll(false);
      }

    },
    detectarViewport(e) {
      if(window.innerWidth < 767.98){
        this.grgFuncionChangeViewPort(true);
      }
      else{
        this.grgFuncionChangeViewPort(false);
      }
    }
  } 
}
</script>

<style>
.ql-editor{
  height: auto !important;
}
#app .row+.row {
    margin-top: 0px !important;
}
.main{
  max-width: 2560px;
  margin: 0 auto;
}
#app {
  font-family: Raleway_Regular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:black;
}
a:hover{
    text-decoration: none !important;
}
@font-face {
  font-family: Raleway_Regular;
  src: url(./assets/font/raleway/Raleway-Regular.ttf);
}
@font-face {
  font-family: Raleway_Bold;
  src: url(./assets/font/raleway/Raleway-Bold.ttf);
}
@font-face {
  font-family: Raleway_Extra_Bold;
  src: url(./assets/font/raleway/Raleway-ExtraBold.ttf);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.grg_container_color{
    background-color: var(--background_color);
}
.grg_font_family_rr{
    font-family: Raleway_Regular;
}
.grg_font_family_rb{
    font-family: Raleway_Bold;
}
.grg_font_family_reb{
    font-family: Raleway_Extra_Bold;
}
.grg_font_outline{
    -webkit-text-stroke: 1px var(--color_outline);
    color: transparent;
    text-shadow:var(--pos_x) var(--pos_y) var(--color_solid);
}
.grg_first_letter{
  float: left;
  margin: .9rem 0 0.9rem;
  margin-right: 0.6rem;
}


.grg_text_extra_small{
  font-size: calc(.5rem + .5vw + 1px);
  /* font-size: calc(.5rem + .51vw + 0px); */
}
.grg_text_small{
  font-size: calc(.6rem + .5vw + 1px);
}
.grg_text_medium{
  font-size: calc(.7rem + .5vw + 1px);
  /* font-size: calc(.7rem + .45vw + 1px); */
  /* letter-spacing: 0.9px; */
}
.grg_text_big{
    font-size: calc(.8rem + .5vw + 1px);
}
.grg_text_extra_big{
    font-size: calc(.9rem + .5vw + 1px);
}
.grg_text_bigger{
    font-size: calc(1rem + .5vw + 5px);
}


.grg_title_extra_small{
  font-size: calc(2rem + 1.5vw + -10px);
}
.grg_title_small{
  font-size: calc(2rem + 2vw + -10px);
} 
.grg_title_medium{
  font-size: calc(3rem + 2vw + -20px);
}
.grg_title_big{
  font-size: calc(3rem + 2vw + -10px);
}
.grg_title_extra_big{
  font-size: calc(4rem + 3vw + -10px);
}
.grg_title_bigger{
  font-size: calc(4rem + 4vw + -10px);
}


.grg_evitar_seleccion{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Versión sin prefijo, actualmente compatible con Chrome y Opera */
}



.grg_container_hover{
    width: fit-content;
    height:fit-content;
    position: relative;
}
.grg_btn{
    position: relative;
    padding: 0.375rem 0.75rem;
    background-color: var(--background_color);
    color: var(--color);
    line-height: 1.5;
    transition: all 250ms linear;
    transform-style: preserve-3d;
}
.grg_btn:before{
    content: attr(data-text);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    background-color: var(--color);
    color: var(--background_color);
    border: 1px solid var(--background_color);
    transform: rotateX(180deg);
    -moz-transform: rotateX(360deg);
    display: flex;
    justify-content: center;
    align-items: center;
}
.grg_container_hover:hover .grg_btn_animacion{
    transform: rotateX(180deg);         
}
.grg_container_hover a:hover .grg_btn::before{
    -moz-transform: rotateX(180deg);
}
.grg_container_hover:hover .grg_btn[data-btn="transparente"]{
    background-color: transparent;
    color: transparent;
}
.grg_btn[data-btn="transparente"]{
    background-color: var(--background_color);
    color: #3a2f25;
}
.grg_btn[data-btn="transparente"]:before{
  content: '';
    background-color: transparent;
    color: white;
    border: 1px solid var(--background_color);
}
.grg_container_hover:hover .grg_btn[data-btn="transparente"]:before{
    content: attr(data-text);
}
.grg_container_hover:hover .grg_btn[data-btn="rojo"]{
    background-color: transparent;
    color: transparent;
}
.grg_btn[data-btn="rojo"]{
    background-color: var(--background_color);
    color: #3a2f25;
}
.grg_btn[data-btn="rojo"]:before{
  content: '';
    background-color: transparent;
    color: #ff595b;
    border: 1px solid var(--background_color);
}
.grg_container_hover:hover .grg_btn[data-btn="rojo"]:before{
    content: attr(data-text);
}




/* Estilo para mapa de vacantes */
.grg_page_mapa .mapael{
	position: relative;
}
.grg_page_mapa .mapael .map {
    position: relative;
    margin-bottom:10px;
	/* background-color: green; */
}
.grg_page_mapa .container{
	/* max-width: 760px;
  margin: auto;
  position:relative; */
}
.grg_page_mapa .mapTooltip{
	position: absolute;
	background-color: #fff;
	border-radius: 50px;
	font-family: Raleway_Bold;
	padding: 5px 30px;
	z-index: 1000;
	color: #404042;
    /* margin: -70px 0px 0 -80px; */
}
.grg_page_mapa .mapTooltip:before {
    /* content: "\A";
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    left: calc(50% - 10px);
    transform: rotate(270deg);
    bottom: -15px; */
}
.grg_page_mapa .map svg{
	filter: drop-shadow(-5px 5px 5px black);
}
.grg_page_mapa .plot:hover{
	fill: rgb(186, 32, 60);
	stroke: rgb(186, 32, 60);
    cursor: "pointer";
	stroke-width: 1;
}



@media (min-width: 2000px){
.container,.grg_section_boton_fixed{
  max-width: 2560px !important;
  margin: 0 auto;
}
}


@media (min-width: 1200px){}
@media (min-width: 992px) {}
@media (min-width: 768px) {
  #app {
    width: calc(100% - 50px);
  }
}
@media (min-width: 576px) {}


@media (max-width: 1199.98px){
}
@media (max-width: 991.98px) {
  .grg_text_extra_small{
    font-size: calc(.5rem + .5vw + 2px);
  }
  .grg_text_small{
    font-size: calc(.6rem + .5vw + 2px);
  }
  .grg_text_medium{
    font-size: calc(.7rem + .5vw + 2px);
  }
  .grg_text_big{
      font-size: calc(.8rem + .5vw + 2px);
  }
  .grg_text_extra_big{
      font-size: calc(.9rem + .5vw + 2px);
  }
}
@media (max-width: 767.98px) {
  .grg_first_letter{
        margin: .3rem 0 0;
        margin-right: 0.6rem;
        font-size: calc(1rem + 12.5vw + 1px) !important;
  }
   .grg_text_extra_small{
    font-size: calc(.5rem + .5vw + 3px);
  }
  .grg_text_small{
    font-size: calc(.6rem + .5vw + 3px);
  }
  .grg_text_medium{
    font-size: calc(.7rem + .5vw + 3px);
  }
  .grg_text_big{
      font-size: calc(.8rem + .5vw + 3px);
  }
  .grg_text_extra_big{
      font-size: calc(.9rem + .5vw + 3px);
  }
  .grg_text_bigger{
      font-size: calc(1rem + .5vw + 2px);
  }
  



  .grg_title_extra_small{
    font-size: calc(1rem + 3.5vw + -5px);
  }
  .grg_title_small{
    font-size: calc(1rem + 4.5vw + -5px);
  } 
  .grg_title_medium{
    font-size: calc(1rem + 5.5vw + -5px);
  }
  .grg_title_big{
    font-size: calc(1rem + 6.5vw + -5px);
  }
  .grg_title_extra_big{
    font-size: calc(1rem + 7.5vw + -5px);
  }
  .grg_title_bigger{
    font-size: calc(1rem + 8.5vw + -5px);
  }
}
@media (max-width: 575.98px) {
}
</style>
