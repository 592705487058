import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'jquery-mapael';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


Vue.config.productionTip = false;
Vue.prototype.$http = axios;
// Vue.prototype.$baseURL = 'http://pruebasmaralero.ga/'
// Vue.prototype.$baseURL = 'http://localhost/'
// Vue.prototype.$baseURL = 'http://192.168.1.73/'
// Vue.prototype.$baseURL = 'http://gruporestauranterogigante.com.mx/';
Vue.prototype.$baseURL = 'https://gruporestauranterogigante.com.mx/';

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')