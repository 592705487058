<template>
  <div class="grg_componente_slider">
    <section class="grg_modulo_slider">
      <div
        id="grg_slider_nuestras_marcas"
        class="carousel slide carousel-fade"
        data-ride="carousel"
        data-interval="3000"
      >
        <ol class="carousel-indicators">
          <li
            v-for="slider of sliders"
            :key="slider.id"
            data-target="#grg_slider_nuestras_marcas"
            :data-slide-to="slider.posicion"
            :class="{ active: slider.posicion == 0 }"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div
            v-for="slider of sliders"
            :key="slider.id"
            class="carousel-item"
            :class="{ active: slider.posicion == 0 }"
          >
            <img
              class="d-block w-100"
              :src="obtenerImagenSlider(slider.imagen)"
              alt="First slide"
            />
            <div class="carousel-caption d-none d-md-block">
              <img
                :width="slider.width_logo"
                :src="obtenerlogo(slider.logo)"
                alt=""
              />
              <h1 class="grg_text_big">{{ slider.descripcion }}</h1>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#grg_slider_nuestras_marcas"
          role="button"
          data-slide="prev"
        >
          <span aria-hidden="true"
            ><img
              class="grg_elemento_flechas_slider"
              width="40%"
              src="@/assets/img/utilerias/flecha_izquierda.webp"
              alt=""
          /></span>
          <span class="sr-only">Anterior</span>
        </a>
        <a
          class="carousel-control-next"
          href="#grg_slider_nuestras_marcas"
          role="button"
          data-slide="next"
        >
          <span aria-hidden="true">
            <img
              class="grg_elemento_flechas_slider"
              width="40%"
              src="@/assets/img/utilerias/flecha_derecha.webp"
              alt=""
          /></span>
          <span class="sr-only">Siguiente</span>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    sliders: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    obtenerImagenSlider(imagen) {
      if (imagen != "") {
        return this.$baseURL + imagen;
        // return require('@/assets/img/slider/'+imagen)
      }
    },
    obtenerlogo(imagen) {
      if (imagen != "") {
        return require("../assets/img/logos/" + imagen);
      }
    },
  },
};
</script>

<style scoped>
.carousel-indicators {
  margin-right: 0%;
  margin-left: 0%;
  left: 20px;
  right: auto;
  opacity: 1;
  transition: opacity 0.2s linear;
}
/* hacer redondos los bullets */
.carousel-indicators li {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 2px;
  margin-left: 2px;
  border: 2px #fff solid;
  background-color: transparent;
  opacity: 1;
}
/* activar el color a los controladores del slider */
.carousel-indicators .active {
  background-color: #fff;
}
/* alejar a los laterales las flechas del slider */
.carousel-control-prev,
.carousel-control-next {
  width: 7%;
  opacity: 1;
}
/* subir los logos del slider hacia arriba */
.carousel-caption {
  bottom: 0;
  right: 5%;
  left: 5%;
  /* background-color: #0000008c; */
}
.carousel-inner img {
  /* filter: brightness(0.6); */
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
  .carousel-indicators {
    display: none;
  }
}
@media (max-width: 575.98px) {
  /* .carousel-indicators li {
        height: 7px;
        width: 7px;
        border: 1px #fff solid;
    } */
  .carousel-control-prev,
  .carousel-control-next {
    /* width: 7%; */
    opacity: 0;
  }
}
</style>