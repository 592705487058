import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        grg_var_scroll: false,
        grg_var_view_port_md: false,
        grg_var_transparencia: true,
    },
    mutations: {
        grgFuncionChangeScroll(state, opcion) {
            state.grg_var_scroll = opcion;
        },
        grgFuncionChangeViewPort(state, opcion) {
            state.grg_var_view_port_md = opcion;
        },
        grgFuncionChangeTransparencia(state, opcion) {
            state.grg_var_transparencia = opcion;
        }
    },
    actions: {},
    modules: {}
})